import React, { useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import cn from "classnames"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"

import errorIcon from "../../../assets/error.svg"
import { EditableField } from "~/components/Forms/AccountSettingsForm/Fields/EditableFields/EditableField"
import {
  Body,
  Button,
  Fieldset,
  TextAreaInput,
  TextInput,
} from "~/components/ui"
import { useUpdateDataMutation } from "~/state/api/profile"
import { ErrorResponse } from "~/types/global-types"
import getFormValidators from "~/utils/formValidation"

import css from "./UserUpdateTextField.module.scss"

type EditableTextFormProps = {
  [objectKey: string]: string
}

type UserUpdateTextFieldProps = {
  title: string
  initialValue: EditableTextFormProps
  placeholder: string
  keyToChange: string
  apiKey: string
  defaultValue?: string
  required?: boolean
  emptyText?: string
  maxLength?: number
  indication?: string
  buttonMessage?: string
  type?: "text" | "email" | string
  prefix?: string
}

export function UserUpdateTextField({
  title,
  initialValue,
  placeholder,
  keyToChange,
  apiKey,
  defaultValue,
  required = true,
  emptyText,
  maxLength,
  indication,
  buttonMessage,
  type,
  prefix,
  ...props
}: UserUpdateTextFieldProps) {
  const intl = useIntl()
  const { requiredString, email: emailValidator } = getFormValidators(intl)
  const [updateData, { isLoading, isError, error }] = useUpdateDataMutation()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [value, setValue] = useState<EditableTextFormProps>(initialValue)
  const [count, setCount] = useState<number>(initialValue[keyToChange].length)
  const [isChange, setIsChange] = useState(false)

  const toogleEditMode = () => {
    setEditMode(!editMode)
  }

  const validationSchema = useMemo(
    () =>
      yup.object().shape(
        required
          ? {
              [keyToChange]: type === "email" ? emailValidator : requiredString,
            }
          : {}
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { register, ...form } = useForm<EditableTextFormProps>({
    resolver: yupResolver(validationSchema),
    defaultValues:
      value[keyToChange] === "" && defaultValue
        ? {
            [keyToChange]: defaultValue,
          }
        : value,
  })

  const submit = (values: EditableTextFormProps) => {
    updateData({
      [apiKey]: values[keyToChange],
    }).then(value => {
      if (!("error" in value)) {
        setValue(values)
        setEditMode(false)
      }
    })
    setIsChange(false)
  }

  const { errors, isSubmitted } = form.formState

  return (
    <EditableField
      title={title}
      actionText={
        editMode
          ? intl.formatMessage({
              id: "form/action:cancel",
            })
          : value[keyToChange] === ""
          ? intl.formatMessage({
              id: "form/action:add",
            })
          : intl.formatMessage({
              id: "form/action:modify",
            })
      }
      actionCallback={toogleEditMode}
      {...props}
    >
      <div className={css.root}>
        {editMode ? (
          <form onSubmit={form.handleSubmit(submit)} noValidate>
            {indication && (
              <Body className={css.indicationBody} variant="body3">
                {indication}
              </Body>
            )}

            <Fieldset error={errors[keyToChange]?.message}>
              {!maxLength ? (
                <TextInput
                  {...register(keyToChange)}
                  placeholder={placeholder}
                  errors={errors[keyToChange]}
                  className={cn(css.inputText, {
                    [css.inputError]: isError && isSubmitted && !isChange,
                  })}
                  type={type}
                  prefix={prefix}
                  onChange={() => setIsChange(true)}
                />
              ) : (
                <>
                  <TextAreaInput
                    rows={3}
                    {...register(keyToChange)}
                    placeholder={placeholder}
                    errors={errors[keyToChange]}
                    maxLength={maxLength}
                    onChange={e => {
                      setIsChange(true)
                      setCount((e.target as HTMLTextAreaElement).value.length)
                    }}
                    className={cn(css.inputText, {
                      [css.inputError]: isError && isSubmitted && !isChange,
                    })}
                  />
                  <Body variant="body3" className={css.counter}>
                    {count}/{maxLength}
                  </Body>
                </>
              )}
            </Fieldset>
            {/* This error message is displayed in english */}
            {isError && isSubmitted && !isChange && (
              <div className={css.messageWrap}>
                <img loading="lazy" src={errorIcon} alt="" />
                <Body variant="body3" color="original" noMargin>
                  {
                    (error as unknown as { data?: ErrorResponse })?.data
                      ?.details
                  }
                </Body>
              </div>
            )}

            <Button
              color="original"
              className={css.button}
              type="submit"
              disabled={isLoading}
            >
              {buttonMessage ? (
                <FormattedMessage id={buttonMessage} />
              ) : (
                <FormattedMessage id="profile/text:save-modification" />
              )}
            </Button>
          </form>
        ) : (
          <Body className={css.actualValue} color="gray1">
            {value[keyToChange] !== ""
              ? (prefix ?? "") + value[keyToChange]
              : emptyText
              ? emptyText
              : ""}
          </Body>
        )}
      </div>
    </EditableField>
  )
}
