import React, { useEffect, useState } from "react"

import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"

import { EditableField } from "~/components/Forms/AccountSettingsForm/Fields/EditableFields/EditableField"
import { Body, Button, Fieldset, TextInput } from "~/components/ui"
import { useCancelStripeSubscriptionMutation } from "~/state/api/subscriptions"
import { subscriptionSelector } from "~/state/modules/subscriptionsInfo"
import { userSelector } from "~/state/modules/userInfo"

import css from "./CancelSubscriptionField.module.scss"

type CancelSubscriptionFormProps = {
  email: string
}

type CancelSubscriptionFieldProps = {
  handleCancel: () => void
  title?: string
}

export function CancelSubscriptionField({
  handleCancel,
  ...props
}: CancelSubscriptionFieldProps) {
  const intl = useIntl()
  const user = useSelector(userSelector)
  const subscription = useSelector(subscriptionSelector)

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const { register, handleSubmit } = useForm<CancelSubscriptionFormProps>()

  const [
    cancelStripeSubscription,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useCancelStripeSubscriptionMutation()

  useEffect(() => {
    if (!isUpdateLoading && !isUpdateError && isUpdateSuccess) {
      //Wait until backend refresh
      setTimeout(() => {
        setLoading(false)
        setEditMode(false)
        handleCancel()
      }, 1000)
    }
  }, [isUpdateLoading, isUpdateError, isUpdateSuccess, handleCancel])

  const cancelSubscription = ({ email }: CancelSubscriptionFormProps) => {
    if (email === user?.email) {
      if (subscription?.product_id) {
        setLoading(true)
        cancelStripeSubscription({ product_id: subscription?.product_id })
      } else {
        setErrorMessage(
          intl.formatMessage({
            id: "profile/text:cancel_subscription_error",
          })
        )
      }
    } else {
      setErrorMessage(
        intl.formatMessage({
          id: "profile/text:cancel_subscription_wrong_email",
        })
      )
    }
  }

  return (
    <EditableField
      title={
        isEditMode
          ? intl.formatMessage({ id: "profile/text:title_cancel_subscription" })
          : ""
      }
      actionCallback={() => setEditMode(false)}
      actionText={
        isEditMode
          ? intl.formatMessage({ id: "profile/action:field-cancel" })
          : ""
      }
      {...props}
    >
      <div className={css.root}>
        {!isLoading &&
          (isEditMode ? (
            <>
              <Body color="gray2">
                <FormattedMessage id="profile/action:cancel_subscription_instructions" />
              </Body>
              <form
                className={css.formRoot}
                onSubmit={handleSubmit(cancelSubscription)}
                noValidate
              >
                <Fieldset error={errorMessage}>
                  <TextInput
                    {...register("email")}
                    placeholder={intl.formatMessage({
                      id: "profile/text:cancel_subscription_confirm_placeholder",
                    })}
                  />
                </Fieldset>

                <Button className={css.button} type="submit" color="original">
                  <FormattedMessage id="profile/action:cancel_subscription_confirm_button" />
                </Button>
              </form>
            </>
          ) : (
            <Body
              color="gray2"
              semiBold
              onClick={() => setEditMode(true)}
              className={css.clickableText}
            >
              <FormattedMessage id="profile/action:cancel_subscription" />
            </Body>
          ))}
      </div>
    </EditableField>
  )
}
