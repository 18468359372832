type ProductInfo = {
  nameTraductionId: string
  nbMonth: number
  price: number
}

export const PRODUCT_NAMES: { [id: string]: ProductInfo } = {
  premium_1_month_prod_january_2020: {
    nameTraductionId: "profile/text:subscription_type_month",
    nbMonth: 1,
    price: 5.99,
  },
  premium_12_months_prod_xmas: {
    nameTraductionId: "profile/text:subscription_type_year",
    nbMonth: 12,
    price: 22.99,
  },
  premium_12_months_prod: {
    nameTraductionId: "profile/text:subscription_type_year",
    nbMonth: 12,
    price: 29.99,
  },
  premium_1_month_prod: {
    nameTraductionId: "profile/text:subscription_type_month",
    nbMonth: 1,
    price: 6.99,
  },
  premium_12_months: {
    nameTraductionId: "profile/text:subscription_type_year",
    nbMonth: 12,
    price: 99.99,
  },
  premium_1_month: {
    nameTraductionId: "profile/text:subscription_type_month",
    nbMonth: 1,
    price: 9.99,
  },
}
