import React, { useEffect, useState } from "react"

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"

import { EditableField } from "~/components/Forms/AccountSettingsForm/Fields/EditableFields/EditableField"
import { Body, Button } from "~/components/ui"
import {
  subscriptionsApi,
  useUpdateStripeSubscriptionMutation,
} from "~/state/api/subscriptions"
import { subscriptionSelector } from "~/state/modules/subscriptionsInfo"

import css from "./UpdatePaymentMethodField.module.scss"

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: "#757575",
      color: "black",
      fontWeight: 400,
      fontSize: "14px",
      ":focus": {
        iconColor: "#000000",
      },
      ":-webkit-autofill": {
        color: "red",
      },
      "::placeholder": {
        color: "#757575",
      },
    },

    invalid: {
      iconColor: "#F5184D",
      color: "#F5184D",
    },
  },
}

export function UpdatePaymentMethodField() {
  const intl = useIntl()

  const stripe = useStripe()
  const elements = useElements()

  const subscription = useSelector(subscriptionSelector)

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()

  const { refetch } =
    subscriptionsApi.endpoints.subscriptions.useQuerySubscription(null)

  const [
    updateStripeSubscription,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateStripeSubscriptionMutation()

  useEffect(() => {
    if (!isUpdateLoading && !isUpdateError && isUpdateSuccess) {
      refetch()
      setLoading(false)
    }
  }, [isUpdateLoading, isUpdateError, isUpdateSuccess, refetch])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Block native form submission.
    event.preventDefault()

    if (!stripe || !elements || isUpdateLoading || isUpdateError) {
      return
    }

    const cardElement = elements.getElement(CardNumberElement)

    if (cardElement) {
      stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
        })
        .then(({ error, paymentMethod }) => {
          if (!error) {
            setLoading(true)
            if (paymentMethod?.id && subscription?.product_id) {
              updateStripeSubscription({
                payment_method_id: paymentMethod.id,
                product_id: subscription.product_id,
              })
              setEditMode(false)
            }
          }
          setErrorMessage(error?.message ?? undefined)
        })
    }
  }

  return (
    <>
      <EditableField
        title={intl.formatMessage({ id: "profile/text:credit-card-subtitle" })}
        actionText={
          isEditMode
            ? intl.formatMessage({ id: "profile/action:field-cancel" })
            : !subscription
            ? intl.formatMessage({ id: "profile/action:field-add" })
            : intl.formatMessage({ id: "profile/action:field-update" })
        }
        actionCallback={() => setEditMode(!isEditMode)}
      >
        {!isLoading &&
          (!isEditMode ? (
            <div className={css.cardDisplay}>
              <svg
                width="24"
                height="18"
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.11409 17.3423H20.859C22.953 17.3423 23.9731 16.2953 23.9731 14.255V3.08725C23.9731 1.04698 22.9262 0 20.859 0H3.11409C1.04697 0 0 1.04698 0 3.08725V14.255C0 16.2953 1.04697 17.3423 3.11409 17.3423ZM1.44964 3.16778C1.44964 2.04027 2.04026 1.47651 3.14093 1.47651H20.8322C21.906 1.47651 22.5235 2.04027 22.5235 3.16778V3.91946H1.44964V3.16778ZM3.14093 15.8926C2.04026 15.8926 1.44964 15.302 1.44964 14.1745V6.22819H22.5235V14.1745C22.5235 15.302 21.906 15.8926 20.8322 15.8926H3.14093ZM4.48321 13.8792H7.0067C7.59731 13.8792 8.02682 13.4765 8.02682 12.8859V10.9799C8.02682 10.4161 7.62415 9.98658 7.0067 9.98658H4.48321C3.8926 9.98658 3.46306 10.3893 3.46306 10.9799V12.8859C3.46306 13.4497 3.8926 13.8792 4.48321 13.8792Z"
                  fill="#757575"
                />
              </svg>
              <Body semiBold color="gray1">
                {subscription?.extra?.last4 &&
                  `**** **** **** ${subscription?.extra?.last4}`}
              </Body>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className={css.root}>
              <CardNumberElement
                options={{ ...CARD_OPTIONS, showIcon: true }}
                className={css.cardField}
              />
              <div className={css.splitedCardForm}>
                <CardExpiryElement
                  options={CARD_OPTIONS}
                  className={css.cardField}
                />
                <CardCvcElement
                  options={CARD_OPTIONS}
                  className={css.cardField}
                />
              </div>
              {errorMessage && (
                <Body color="original" className={css.errorMessage}>
                  {errorMessage}
                </Body>
              )}
              <Button
                className={css.button}
                color="original"
                type="submit"
                disabled={!stripe}
              >
                <FormattedMessage id="profile/text:save-modification" />
              </Button>
            </form>
          ))}
      </EditableField>
    </>
  )
}
