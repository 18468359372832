import React, { useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"

import errorIcon from "../../../assets/error.svg"
import { EditableField } from "~/components/Forms/AccountSettingsForm/Fields/EditableFields/EditableField"
import { Body, Button, Fieldset, PasswordInput } from "~/components/ui"
import { useResetPasswordConnectedMutation } from "~/state/api/auth"
import { ErrorResponse } from "~/types/global-types"
import getFormValidators from "~/utils/formValidation"

import css from "./UserUpdatePasswordField.module.scss"

type EditableTextFormProps = {
  [objectKey: string]: string
}

type UserUpdatePasswordFieldProps = {
  title: string
  placeholder: string
}

export function UserUpdatePasswordField({
  title,
  placeholder,
  ...props
}: UserUpdatePasswordFieldProps) {
  const intl = useIntl()
  const [resetPasswordConnected, { isLoading, isError, error }] =
    useResetPasswordConnectedMutation()
  const [editMode, setEditMode] = useState<boolean>(false)

  const toogleEditMode = () => {
    setEditMode(!editMode)
  }

  const { password } = getFormValidators(intl)

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        password,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { register, ...form } = useForm<EditableTextFormProps>({
    resolver: yupResolver(validationSchema),
  })

  const submit = (values: EditableTextFormProps) => {
    resetPasswordConnected({
      password: values.password,
    }).then(value => {
      if (!("error" in value)) {
        setEditMode(false)
      }
    })
  }

  const { errors, isSubmitted, isValid } = form.formState

  return (
    <EditableField
      title={title}
      actionText={
        editMode
          ? intl.formatMessage({
              id: "form/action:cancel",
            })
          : intl.formatMessage({
              id: "form/action:modify",
            })
      }
      actionCallback={toogleEditMode}
      {...props}
    >
      {editMode ? (
        <form
          className={css.root}
          onSubmit={form.handleSubmit(submit)}
          noValidate
        >
          <Fieldset
            error={errors.password?.message}
            {...(isValid && { valid: "Disponible" })}
          >
            <PasswordInput
              {...register("password")}
              placeholder={placeholder}
              errors={errors.password}
              type="password"
              className={css.inputPassword}
            />
          </Fieldset>
          {/* This error message is displayed in english */}
          {isError && isSubmitted && (
            <div className={css.messageWrap}>
              <img loading="lazy" src={errorIcon} alt="" />
              <Body variant="body3" color="original" noMargin>
                {(error as unknown as { data?: ErrorResponse })?.data?.details}
              </Body>
            </div>
          )}
          <Button
            color="original"
            className={css.button}
            type="submit"
            disabled={isLoading}
          >
            <FormattedMessage id="profile/text:save-modification" />
          </Button>
        </form>
      ) : (
        <Body color="gray2">*************</Body>
      )}
    </EditableField>
  )
}
