import React from "react"

import Separator from "~/components/Separator/Separator"
import { Heading } from "~/components/ui"

import css from "./SettingsField.module.scss"

type SettingsFieldProps = {
  title?: string
  children: React.ReactNode
}

export function SettingsField({
  title,
  children,
  ...props
}: SettingsFieldProps) {
  return (
    <div className={css.root} {...props}>
      <div className={css.header} {...props}>
        {title && (
          <Heading variant={"small"} className={css.title}>
            {title}
          </Heading>
        )}
      </div>
      <div className={css.childrenContainer}>{children}</div>
      <Separator />
    </div>
  )
}
