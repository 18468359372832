import React from "react"

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe, StripeElementLocale } from "@stripe/stripe-js"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"

import { UpdatePaymentMethodField } from "~/components/Forms/AccountSettingsForm/Fields/EditableFields/UpdatePaymentMethodField/UpdatePaymentMethodField"
import { CancelSubscriptionField } from "~/components/Forms/AccountSettingsForm/Fields/SettingsFields/CancelSubscriptionField/CancelSubscriptionField"
import { SettingsField } from "~/components/Forms/AccountSettingsForm/Fields/SettingsFields/SettingsField"
import PremiumCard from "~/components/PremiumCard/PremiumCard"
import { Body, Heading } from "~/components/ui"
import {
  subscriptionsApi,
  useSubscriptionsQuery,
} from "~/state/api/subscriptions"
import { subscriptionSelector } from "~/state/modules/subscriptionsInfo"

import css from "./SubscriptionSettingsForm.module.scss"

export function SubscriptionSettingsForm() {
  const intl = useIntl()
  const subscription = useSelector(subscriptionSelector)

  const { refetch } =
    subscriptionsApi.endpoints.subscriptions.useQuerySubscription(null)
  useSubscriptionsQuery(null)

  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY ?? "", {
    locale: (intl.locale as StripeElementLocale) || "en",
  })

  if (!subscription || Object.keys(subscription).length === 0) {
    return (
      <div className={css.root}>
        <Heading>
          <FormattedMessage id="profile/text:title-my-subscription" />
        </Heading>
        <PremiumCard />
      </div>
    )
  }

  const isCancelled = !!subscription.cancellation_date
  const hasReceiptLink = !!subscription.extra?.hosted_invoice_url

  return (
    <Elements stripe={stripePromise}>
      <div className={css.root} id="subscription">
        <Heading variant="medium">
          <FormattedMessage id="profile/text:title-my-subscription" />
        </Heading>
        <PremiumCard hideManageLink />
        {!isCancelled && <UpdatePaymentMethodField />}
        {hasReceiptLink && (
          <SettingsField>
            <Body className={css.actionText} color="gray2" semiBold>
              <a
                href={subscription.extra?.hosted_invoice_url}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="profile/action:open_receipt" />
              </a>
            </Body>
          </SettingsField>
        )}
        {!isCancelled && <CancelSubscriptionField handleCancel={refetch} />}
      </div>
    </Elements>
  )
}
