import React from "react"

import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"

import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import Link from "~/components/Link/Link"
import { Body, Button, FormattedBody, Heading } from "~/components/ui"
import { useSubscriptionsQuery } from "~/state/api/subscriptions"
import { subscriptionSelector } from "~/state/modules/subscriptionsInfo"
import { userSelector } from "~/state/modules/userInfo"
import premiumStar from "~/templates/Profile/ProfileHeader/assets/premiumStar.svg"
import { PRODUCT_NAMES } from "~/utils/subscriptions"

import css from "./PremiumCard.module.scss"

type PropType = {
  hideManageLink?: boolean
}

const PremiumCard = ({ hideManageLink }: PropType) => {
  const connectedUser = useSelector(userSelector)
  useSubscriptionsQuery(null)
  const subscription = useSelector(subscriptionSelector)

  if (!connectedUser) {
    return null
  }

  return connectedUser.isPremium && Object.keys(subscription ?? {}).length ? (
    <>
      {subscription ? (
        <div className={css.premiumRoot}>
          <ColorableSVG
            href={premiumStar}
            width={34}
            height={34}
            style={{ top: "14px", right: "10px" }}
          />
          <ColorableSVG
            href={premiumStar}
            width={26}
            height={26}
            style={{ top: "51px", right: "44px" }}
          />
          <div className={css.actualPrice}>
            <FormattedBody
              translationId={
                PRODUCT_NAMES[subscription.product_id] &&
                PRODUCT_NAMES[subscription.product_id].nbMonth === 12
                  ? "premium/text:yearly"
                  : "premium/text:monthly"
              }
              variant="body3"
            />
            <Heading variant="extraExtraLarge">
              {PRODUCT_NAMES[subscription.product_id] &&
                PRODUCT_NAMES[subscription.product_id].price}
              €
            </Heading>
          </div>
          <div className={css.details}>
            <div>
              <FormattedBody
                translationId="premium/text:end-of-subscription-date"
                variant="body4"
              />
              <Body variant="body2" semiBold>
                {formatDate(subscription.expires_date)}
              </Body>
            </div>
            {!hideManageLink && (
              <Link to={"profile/account-my-settings"}>
                <Button variant="tertiary" underline uppercase={false}>
                  <FormattedMessage id="premium/action:manage-subscription" />
                </Button>
              </Link>
            )}
          </div>
        </div>
      ) : null}
    </>
  ) : (
    <div
      className={css.premiumRoot}
      style={{
        flexDirection: "column",
        textAlign: "center",
        padding: "24px 42px",
      }}
    >
      <ColorableSVG
        href={premiumStar}
        width={32}
        height={32}
        style={{ top: "50px", left: "35px" }}
      />
      <ColorableSVG
        href={premiumStar}
        width={22}
        height={22}
        style={{ bottom: "21px", right: "34px" }}
      />
      <ColorableSVG
        href={premiumStar}
        width={20}
        height={20}
        style={{ top: "8px", left: "8px" }}
      />
      <FormattedBody
        translationId="premium/text:subscribe-now"
        variant="body1"
        semiBold
        noMargin
      />
      <Link to={"profile/account-premium"}>
        <Button variant="tertiary" underline uppercase={false}>
          <FormattedMessage id="premium/action:discover" />
        </Button>
      </Link>
    </div>
  )
}

const formatDate = (date: Date): string => {
  const day = (date.getDate() >= 10 ? "" : "0") + date.getDate()
  const month = (date.getMonth() >= 9 ? "" : "0") + (date.getMonth() + 1)

  return `${day}/${month}/${date.getFullYear()}`
}

export default PremiumCard
