import React from "react"

import { useIntl } from "react-intl"
import { useSelector } from "react-redux"

import { UserUpdatePasswordField } from "../Fields/EditableFields/UserUpdatePasswordField/UserUpdatePasswordField"
import { UserUpdateProfilPicture } from "../Fields/EditableFields/UserUpdateProfilPicture/UserUpdateProfilPicture"
import { UserUpdateTextField } from "~/components/Forms/AccountSettingsForm/Fields/EditableFields/UserUpdateTextField/UserUpdateTextField"
import { Heading } from "~/components/ui"
import { userSelector } from "~/state/modules/userInfo"

import css from "./ProfileSettingsForm.module.scss"

export function ProfileSettingsForm() {
  const intl = useIntl()
  const user = useSelector(userSelector)

  return (
    <div className={css.root}>
      <Heading variant="medium">
        {intl.formatMessage({
          id: "profile/text:my-profile",
        })}
      </Heading>

      <UserUpdateProfilPicture user={user} />

      <UserUpdateTextField
        title={intl.formatMessage({
          id: "form/text:label-first-name",
        })}
        initialValue={{ firstName: user?.firstName ?? "" }}
        keyToChange="firstName"
        apiKey="first_name"
        placeholder={intl.formatMessage({
          id: "form/text:label-first-name",
        })}
      />

      <UserUpdateTextField
        title={intl.formatMessage({
          id: "form/text:label-last-name",
        })}
        initialValue={{ lastName: user?.lastName ?? "" }}
        keyToChange="lastName"
        apiKey="last_name"
        placeholder={intl.formatMessage({
          id: "form/text:label-last-name",
        })}
      />

      <UserUpdateTextField
        title={intl.formatMessage({
          id: "form/text:label-username",
        })}
        initialValue={{ username: user?.username ?? "" }}
        keyToChange="username"
        apiKey="username"
        placeholder={intl.formatMessage({
          id: "form/text:label-last-name",
        })}
      />

      <UserUpdateTextField
        title={intl.formatMessage({
          id: "form/text:label-email",
        })}
        initialValue={{ email: user?.email ?? "" }}
        keyToChange="email"
        apiKey="email"
        indication={intl.formatMessage({
          id: "form/text:field-indication-new-email",
        })}
        buttonMessage="profile/text:save-modification-send-validation"
        placeholder={intl.formatMessage({
          id: "form/text:label-email",
        })}
        type="email"
      />

      <UserUpdatePasswordField
        title={intl.formatMessage({
          id: "form/text:label-password",
        })}
        placeholder={intl.formatMessage({
          id: "form/text:label-password",
        })}
      />

      <UserUpdateTextField
        title={intl.formatMessage({
          id: "form/text:label-instagram",
        })}
        initialValue={{ instagramProfile: user?.instagramProfile ?? "" }}
        keyToChange="instagramProfile"
        apiKey="instagram_profile"
        placeholder={intl.formatMessage({
          id: "form/text:label-instagram",
        })}
        required={false}
        prefix="@"
      />

      <UserUpdateTextField
        title={intl.formatMessage({
          id: "form/text:label-biography",
        })}
        initialValue={{ biography: user?.biography ?? "" }}
        keyToChange="biography"
        apiKey="biography"
        indication={intl.formatMessage({
          id: "form/text:field-indication-biography",
        })}
        placeholder={intl.formatMessage({
          id: "form/text:label-biography",
        })}
        emptyText={intl.formatMessage({
          id: "form/text:field-empty-biography",
        })}
        required={false}
        maxLength={150}
      />
    </div>
  )
}
