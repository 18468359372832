import React from "react"

import { useIntl } from "react-intl"

import { LanguageSettingsForm } from "~/components/Forms/AccountSettingsForm/LanguageSettingsForm/LanguageSettingsForm"
import { ProfileSettingsForm } from "~/components/Forms/AccountSettingsForm/ProfileSettingsForm/ProfileSettingsForm"
import { SubscriptionSettingsForm } from "~/components/Forms/AccountSettingsForm/SubscriptionSettingsForm/SubscriptionSettingsForm"
import { Col, Heading, Row } from "~/components/ui"

import css from "./AccountSettingsForm.module.scss"

export function AccountSettingsForm() {
  const intl = useIntl()

  return (
    <div className={css.root}>
      <Heading variant="extraLarge">
        {intl.formatMessage({
          id: "profile/action:edit-my-account",
        })}
      </Heading>

      <Row justify={"between"}>
        <Col width={[12, 6, 6]}>
          <ProfileSettingsForm />
        </Col>
        <Col width={[12, 5, 5]}>
          <SubscriptionSettingsForm />
          <LanguageSettingsForm />
        </Col>
      </Row>
    </div>
  )
}
