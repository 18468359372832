import React from "react"

import { useIntl } from "react-intl"

import { EditableField } from "../EditableField"
import Avatar from "~/components/Avatar/Avatar"
import { Body } from "~/components/ui"
import { User } from "~/models/User"
import {
  useRemoveProfilePictureMutation,
  useUploadProfilPictureMutation,
} from "~/state/api/profile"

import css from "./UserUpdateProfilPicture.module.scss"

type UpdateProfilInputReturn = {
  profilePicture: FileList | null
}

export function UserUpdateProfilPicture({ user }: { user: User | null }) {
  const intl = useIntl()
  const [uploadProfilPicture] = useUploadProfilPictureMutation()

  const [removeProfilePicture] = useRemoveProfilePictureMutation()

  const submitNewPicture = (values: UpdateProfilInputReturn) => {
    if (values.profilePicture && values.profilePicture.length > 0) {
      const formData = new FormData()
      for (let i = 0; i < values.profilePicture.length; i++) {
        const file = values.profilePicture[i]
        formData.append("profile_pic", file)
      }
      uploadProfilPicture(formData)
    }
  }

  return (
    <div className={css.root}>
      <EditableField
        actionText=""
        input={
          <>
            <label htmlFor="profilePicture" className={css.inputLabel}>
              <Body color="original" semiBold variant="body2">
                {user && user.profilePicture !== ""
                  ? intl.formatMessage({
                      id: "form/action:modify",
                    })
                  : intl.formatMessage({
                      id: "form/action:add",
                    })}{" "}
              </Body>
            </label>
            <input
              id="profilePicture"
              name="profilePicture"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={e =>
                submitNewPicture({ profilePicture: e.target.files })
              }
              style={{ display: "none" }}
            />
          </>
        }
      >
        <div className={css.imageCard}>
          {user && (
            <Avatar user={user} variant="large" className={css.avatar} />
          )}
          {user?.profilePicture && (
            <span
              className={css.removeIcon}
              onClick={() => removeProfilePicture(null)}
            />
          )}
        </div>
      </EditableField>
    </div>
  )
}
