import React, { useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"

import errorIcon from "../../../assets/error.svg"
import { EditableField } from "~/components/Forms/AccountSettingsForm/Fields/EditableFields/EditableField"
import { Body, Button, Fieldset, Select } from "~/components/ui"
import { useUpdateDataMutation } from "~/state/api/profile"
import { ErrorResponse } from "~/types/global-types"
import getFormValidators from "~/utils/formValidation"

import css from "./UserUpdateSelectField.module.scss"

interface EditableSelectFormProps {
  [objectKey: string]: string
}

type UserUpdateSelectFieldProps = {
  title: string
  initialValue: EditableSelectFormProps
  possibleValues: Map<string, string>
  keyToChange: string
  apiKey: string
  valueToShow: string | undefined
}

export function UserUpdateSelectField({
  title,
  initialValue,
  possibleValues,
  keyToChange,
  apiKey,
  valueToShow,
  ...props
}: UserUpdateSelectFieldProps) {
  const intl = useIntl()
  const { requiredString } = getFormValidators(intl)
  const [updateData, { isLoading, isError, error }] = useUpdateDataMutation()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [value, setValue] = useState<EditableSelectFormProps>(initialValue)

  const toogleEditMode = () => {
    setEditMode(!editMode)
  }

  const validationSchema = useMemo(
    () => yup.object().shape({ [keyToChange]: requiredString }),
    [keyToChange, requiredString]
  )

  const { register, ...form } = useForm<EditableSelectFormProps>({
    resolver: yupResolver(validationSchema),
    defaultValues: value,
  })

  const submit = (values: EditableSelectFormProps) => {
    updateData({
      [apiKey]: values[keyToChange],
    }).then(value => {
      if (!("error" in value)) {
        setValue(values)
        setEditMode(false)
      }
    })
  }

  const { errors, isSubmitted } = form.formState

  return (
    <EditableField
      title={title}
      actionText={
        editMode
          ? intl.formatMessage({
              id: "form/action:cancel",
            })
          : value[keyToChange] === ""
          ? intl.formatMessage({
              id: "form/action:add",
            })
          : intl.formatMessage({
              id: "form/action:modify",
            })
      }
      actionCallback={toogleEditMode}
      {...props}
    >
      {editMode ? (
        <form
          className={css.root}
          onSubmit={form.handleSubmit(submit)}
          noValidate
        >
          <Fieldset error={errors[keyToChange]?.message}>
            <Select
              {...register(keyToChange)}
              defaultValue={initialValue[keyToChange]}
              className={css.select}
            >
              {[...possibleValues].map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Select>
          </Fieldset>
          {/* This error message is displayed in english */}
          {isError && isSubmitted && (
            <div className={css.messageWrap}>
              <img loading="lazy" src={errorIcon} alt="" />
              <Body variant="body3" color="original" noMargin>
                {(error as unknown as { data?: ErrorResponse })?.data?.details}
              </Body>
            </div>
          )}
          <Button
            color="original"
            className={css.button}
            type="submit"
            disabled={isLoading}
          >
            <FormattedMessage id="profile/text:save-modification" />
          </Button>
        </form>
      ) : (
        <Body color="gray1">
          {possibleValues.get(initialValue[keyToChange]) ?? ""}
        </Body>
      )}
    </EditableField>
  )
}
