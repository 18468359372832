export const ALL_COUNTRIES = [
  {
    cca2: "AF",
    cca3: "AFG",
    translations: {
      en: "Afghanistan",
      fr: "Afghanistan",
      pt: "Afeganistão",
      es: "Afganistán",
      de: "Afghanistan",
      it: "Afghanistan",
    },
    flag: "🇦🇫",
  },
  {
    cca2: "AO",
    cca3: "AGO",
    translations: {
      en: "Angola",
      fr: "Angola",
      pt: "Angola",
      es: "Angola",
      de: "Angola",
      it: "Angola",
    },
    flag: "🇦🇴",
  },
  {
    cca2: "AL",
    cca3: "ALB",
    translations: {
      en: "Albania",
      fr: "Albanie",
      pt: "Albânia",
      es: "Albania",
      de: "Albanien",
      it: "Albania",
    },
    flag: "🇦🇱",
  },
  {
    cca2: "AD",
    cca3: "AND",
    translations: {
      en: "Andorra",
      fr: "Andorre",
      pt: "Andorra",
      es: "Andorra",
      de: "Andorra",
      it: "Andorra",
    },
    flag: "🇦🇩",
  },
  {
    cca2: "AE",
    cca3: "ARE",
    translations: {
      en: "United Arab Emirates",
      fr: "Émirats arabes unis",
      pt: "Emirados Árabes Unidos",
      es: "Emiratos Árabes Unidos",
      de: "Vereinigte Arabische Emirate",
      it: "Emirati Arabi Uniti",
    },
    flag: "🇦🇪",
  },
  {
    cca2: "AR",
    cca3: "ARG",
    translations: {
      en: "Argentina",
      fr: "Argentine",
      pt: "Argentina",
      es: "Argentina",
      de: "Argentinien",
      it: "Argentina",
    },
    flag: "🇦🇷",
  },
  {
    cca2: "AM",
    cca3: "ARM",
    translations: {
      en: "Armenia",
      fr: "Arménie",
      pt: "Arménia",
      es: "Armenia",
      de: "Armenien",
      it: "Armenia",
    },
    flag: "🇦🇲",
  },
  {
    cca2: "AG",
    cca3: "ATG",
    translations: {
      en: "Antigua and Barbuda",
      fr: "Antigua-et-Barbuda",
      pt: "Antígua e Barbuda",
      es: "Antigua y Barbuda",
      de: "Antigua und Barbuda",
      it: "Antigua e Barbuda",
    },
    flag: "🇦🇬",
  },
  {
    cca2: "AU",
    cca3: "AUS",
    translations: {
      en: "Australia",
      fr: "Australie",
      pt: "Austrália",
      es: "Australia",
      de: "Australien",
      it: "Australia",
    },
    flag: "🇦🇺",
  },
  {
    cca2: "AT",
    cca3: "AUT",
    translations: {
      en: "Austria",
      fr: "Autriche",
      pt: "Áustria",
      es: "Austria",
      de: "Österreich",
      it: "Austria",
    },
    flag: "🇦🇹",
  },
  {
    cca2: "AZ",
    cca3: "AZE",
    translations: {
      en: "Azerbaijan",
      fr: "Azerbaïdjan",
      pt: "Azerbeijão",
      es: "Azerbaiyán",
      de: "Aserbaidschan",
      it: "Azerbaijan",
    },
    flag: "🇦🇿",
  },
  {
    cca2: "BI",
    cca3: "BDI",
    translations: {
      en: "Burundi",
      fr: "Burundi",
      pt: "Burundi",
      es: "Burundi",
      de: "Burundi",
      it: "Burundi",
    },
    flag: "🇧🇮",
  },
  {
    cca2: "BE",
    cca3: "BEL",
    translations: {
      en: "Belgium",
      fr: "Belgique",
      pt: "Bélgica",
      es: "Bélgica",
      de: "Belgien",
      it: "Belgio",
    },
    flag: "🇧🇪",
  },
  {
    cca2: "BJ",
    cca3: "BEN",
    translations: {
      en: "Benin",
      fr: "Bénin",
      pt: "Benin",
      es: "Benín",
      de: "Benin",
      it: "Benin",
    },
    flag: "🇧🇯",
  },
  {
    cca2: "BF",
    cca3: "BFA",
    translations: {
      en: "Burkina Faso",
      fr: "Burkina Faso",
      pt: "Burkina Faso",
      es: "Burkina Faso",
      de: "Burkina Faso",
      it: "Burkina Faso",
    },
    flag: "🇧🇫",
  },
  {
    cca2: "BD",
    cca3: "BGD",
    translations: {
      en: "Bangladesh",
      fr: "Bangladesh",
      pt: "Bangladesh",
      es: "Bangladesh",
      de: "Bangladesch",
      it: "Bangladesh",
    },
    flag: "🇧🇩",
  },
  {
    cca2: "BG",
    cca3: "BGR",
    translations: {
      en: "Bulgaria",
      fr: "Bulgarie",
      pt: "Bulgária",
      es: "Bulgaria",
      de: "Bulgarien",
      it: "Bulgaria",
    },
    flag: "🇧🇬",
  },
  {
    cca2: "BH",
    cca3: "BHR",
    translations: {
      en: "Bahrain",
      fr: "Bahreïn",
      pt: "Bahrein",
      es: "Bahrein",
      de: "Bahrain",
      it: "Bahrein",
    },
    flag: "🇧🇭",
  },
  {
    cca2: "BS",
    cca3: "BHS",
    translations: {
      en: "Bahamas",
      fr: "Bahamas",
      pt: "Bahamas",
      es: "Bahamas",
      de: "Bahamas",
      it: "Bahamas",
    },
    flag: "🇧🇸",
  },
  {
    cca2: "BA",
    cca3: "BIH",
    translations: {
      en: "Bosnia and Herzegovina",
      fr: "Bosnie-Herzégovine",
      pt: "Bósnia e Herzegovina",
      es: "Bosnia y Herzegovina",
      de: "Bosnien und Herzegowina",
      it: "Bosnia ed Erzegovina",
    },
    flag: "🇧🇦",
  },
  {
    cca2: "BY",
    cca3: "BLR",
    translations: {
      en: "Belarus",
      fr: "Biélorussie",
      pt: "Bielorússia",
      es: "Bielorrusia",
      de: "Weißrussland",
      it: "Bielorussia",
    },
    flag: "🇧🇾",
  },
  {
    cca2: "BZ",
    cca3: "BLZ",
    translations: {
      en: "Belize",
      fr: "Belize",
      pt: "Belize",
      es: "Belice",
      de: "Belize",
      it: "Belize",
    },
    flag: "🇧🇿",
  },
  {
    cca2: "BO",
    cca3: "BOL",
    translations: {
      en: "Bolivia",
      fr: "Bolivie",
      pt: "Bolívia",
      es: "Bolivia",
      de: "Bolivien",
      it: "Bolivia",
    },
    flag: "🇧🇴",
  },
  {
    cca2: "BR",
    cca3: "BRA",
    translations: {
      en: "Brazil",
      fr: "Brésil",
      pt: "Brasil",
      es: "Brasil",
      de: "Brasilien",
      it: "Brasile",
    },
    flag: "🇧🇷",
  },
  {
    cca2: "BB",
    cca3: "BRB",
    translations: {
      en: "Barbados",
      fr: "Barbade",
      pt: "Barbados",
      es: "Barbados",
      de: "Barbados",
      it: "Barbados",
    },
    flag: "🇧🇧",
  },
  {
    cca2: "BN",
    cca3: "BRN",
    translations: {
      en: "Brunei",
      fr: "Brunei",
      pt: "Brunei",
      es: "Brunei",
      de: "Brunei",
      it: "Brunei",
    },
    flag: "🇧🇳",
  },
  {
    cca2: "BT",
    cca3: "BTN",
    translations: {
      en: "Bhutan",
      fr: "Bhoutan",
      pt: "Butão",
      es: "Bután",
      de: "Bhutan",
      it: "Bhutan",
    },
    flag: "🇧🇹",
  },
  {
    cca2: "BW",
    cca3: "BWA",
    translations: {
      en: "Botswana",
      fr: "Botswana",
      pt: "Botswana",
      es: "Botswana",
      de: "Botswana",
      it: "Botswana",
    },
    flag: "🇧🇼",
  },
  {
    cca2: "CF",
    cca3: "CAF",
    translations: {
      en: "Central African Republic",
      fr: "République centrafricaine",
      pt: "República Centro-Africana",
      es: "República Centroafricana",
      de: "Zentralafrikanische Republik",
      it: "Repubblica Centrafricana",
    },
    flag: "🇨🇫",
  },
  {
    cca2: "CA",
    cca3: "CAN",
    translations: {
      en: "Canada",
      fr: "Canada",
      pt: "Canadá",
      es: "Canadá",
      de: "Kanada",
      it: "Canada",
    },
    flag: "🇨🇦",
  },
  {
    cca2: "CH",
    cca3: "CHE",
    translations: {
      en: "Switzerland",
      fr: "Suisse",
      pt: "Suíça",
      es: "Suiza",
      de: "Schweiz",
      it: "Svizzera",
    },
    flag: "🇨🇭",
  },
  {
    cca2: "CL",
    cca3: "CHL",
    translations: {
      en: "Chile",
      fr: "Chili",
      pt: "Chile",
      es: "Chile",
      de: "Chile",
      it: "Cile",
    },
    flag: "🇨🇱",
  },
  {
    cca2: "CN",
    cca3: "CHN",
    translations: {
      en: "China",
      fr: "Chine",
      pt: "China",
      es: "China",
      de: "China",
      it: "Cina",
    },
    flag: "🇨🇳",
  },
  {
    cca2: "CI",
    cca3: "CIV",
    translations: {
      en: "Ivory Coast",
      fr: "Côte d'Ivoire",
      pt: "Costa do Marfim",
      es: "Costa de Marfil",
      de: "Elfenbeinküste",
      it: "Costa d'Avorio",
    },
    flag: "🇨🇮",
  },
  {
    cca2: "CM",
    cca3: "CMR",
    translations: {
      en: "Cameroon",
      fr: "Cameroun",
      pt: "Camarões",
      es: "Camerún",
      de: "Kamerun",
      it: "Camerun",
    },
    flag: "🇨🇲",
  },
  {
    cca2: "CD",
    cca3: "COD",
    translations: {
      en: "DR Congo",
      fr: "Congo (Rép. dém.)",
      pt: "República Democrática do Congo",
      es: "Congo (Rep. Dem.)",
      de: "Kongo (Dem. Rep.)",
      it: "Congo (Rep. Dem.)",
    },
    flag: "🇨🇩",
  },
  {
    cca2: "CG",
    cca3: "COG",
    translations: {
      en: "Republic of the Congo",
      fr: "Congo",
      pt: "Congo",
      es: "Congo",
      de: "Kongo",
      it: "Congo",
    },
    flag: "🇨🇬",
  },
  {
    cca2: "CO",
    cca3: "COL",
    translations: {
      en: "Colombia",
      fr: "Colombie",
      pt: "Colômbia",
      es: "Colombia",
      de: "Kolumbien",
      it: "Colombia",
    },
    flag: "🇨🇴",
  },
  {
    cca2: "KM",
    cca3: "COM",
    translations: {
      en: "Comoros",
      fr: "Comores",
      pt: "Comores",
      es: "Comoras",
      de: "Komoren",
      it: "Comore",
    },
    flag: "🇰🇲",
  },
  {
    cca2: "CV",
    cca3: "CPV",
    translations: {
      en: "Cape Verde",
      fr: "Îles du Cap-Vert",
      pt: "Cabo Verde",
      es: "Cabo Verde",
      de: "Kap Verde",
      it: "Capo Verde",
    },
    flag: "🇨🇻",
  },
  {
    cca2: "CR",
    cca3: "CRI",
    translations: {
      en: "Costa Rica",
      fr: "Costa Rica",
      pt: "Costa Rica",
      es: "Costa Rica",
      de: "Costa Rica",
      it: "Costa Rica",
    },
    flag: "🇨🇷",
  },
  {
    cca2: "CU",
    cca3: "CUB",
    translations: {
      en: "Cuba",
      fr: "Cuba",
      pt: "Cuba",
      es: "Cuba",
      de: "Kuba",
      it: "Cuba",
    },
    flag: "🇨🇺",
  },
  {
    cca2: "CY",
    cca3: "CYP",
    translations: {
      en: "Cyprus",
      fr: "Chypre",
      pt: "Chipre",
      es: "Chipre",
      de: "Zypern",
      it: "Cipro",
    },
    flag: "🇨🇾",
  },
  {
    cca2: "CZ",
    cca3: "CZE",
    translations: {
      en: "Czechia",
      fr: "Tchéquie",
      pt: "Chéquia",
      es: "Chequia",
      de: "Tschechien",
      it: "Cechia",
    },
    flag: "🇨🇿",
  },
  {
    cca2: "DE",
    cca3: "DEU",
    translations: {
      en: "Germany",
      fr: "Allemagne",
      pt: "Alemanha",
      es: "Alemania",
      de: "Deutschland",
      it: "Germania",
    },
    flag: "🇩🇪",
  },
  {
    cca2: "DJ",
    cca3: "DJI",
    translations: {
      en: "Djibouti",
      fr: "Djibouti",
      pt: "Djibouti",
      es: "Djibouti",
      de: "Dschibuti",
      it: "Gibuti",
    },
    flag: "🇩🇯",
  },
  {
    cca2: "DM",
    cca3: "DMA",
    translations: {
      en: "Dominica",
      fr: "Dominique",
      pt: "Dominica",
      es: "Dominica",
      de: "Dominica",
      it: "Dominica",
    },
    flag: "🇩🇲",
  },
  {
    cca2: "DK",
    cca3: "DNK",
    translations: {
      en: "Denmark",
      fr: "Danemark",
      pt: "Dinamarca",
      es: "Dinamarca",
      de: "Dänemark",
      it: "Danimarca",
    },
    flag: "🇩🇰",
  },
  {
    cca2: "DO",
    cca3: "DOM",
    translations: {
      en: "Dominican Republic",
      fr: "République dominicaine",
      pt: "República Dominicana",
      es: "República Dominicana",
      de: "Dominikanische Republik",
      it: "Repubblica Dominicana",
    },
    flag: "🇩🇴",
  },
  {
    cca2: "DZ",
    cca3: "DZA",
    translations: {
      en: "Algeria",
      fr: "Algérie",
      pt: "Argélia",
      es: "Argelia",
      de: "Algerien",
      it: "Algeria",
    },
    flag: "🇩🇿",
  },
  {
    cca2: "EC",
    cca3: "ECU",
    translations: {
      en: "Ecuador",
      fr: "Équateur",
      pt: "Equador",
      es: "Ecuador",
      de: "Ecuador",
      it: "Ecuador",
    },
    flag: "🇪🇨",
  },
  {
    cca2: "EG",
    cca3: "EGY",
    translations: {
      en: "Egypt",
      fr: "Égypte",
      pt: "Egito",
      es: "Egipto",
      de: "Ägypten",
      it: "Egitto",
    },
    flag: "🇪🇬",
  },
  {
    cca2: "ER",
    cca3: "ERI",
    translations: {
      en: "Eritrea",
      fr: "Érythrée",
      pt: "Eritreia",
      es: "Eritrea",
      de: "Eritrea",
      it: "Eritrea",
    },
    flag: "🇪🇷",
  },
  {
    cca2: "ES",
    cca3: "ESP",
    translations: {
      en: "Spain",
      fr: "Espagne",
      pt: "Espanha",
      es: "España",
      de: "Spanien",
      it: "Spagna",
    },
    flag: "🇪🇸",
  },
  {
    cca2: "EE",
    cca3: "EST",
    translations: {
      en: "Estonia",
      fr: "Estonie",
      pt: "Estónia",
      es: "Estonia",
      de: "Estland",
      it: "Estonia",
    },
    flag: "🇪🇪",
  },
  {
    cca2: "ET",
    cca3: "ETH",
    translations: {
      en: "Ethiopia",
      fr: "Éthiopie",
      pt: "Etiópia",
      es: "Etiopía",
      de: "Äthiopien",
      it: "Etiopia",
    },
    flag: "🇪🇹",
  },
  {
    cca2: "FI",
    cca3: "FIN",
    translations: {
      en: "Finland",
      fr: "Finlande",
      pt: "Finlândia",
      es: "Finlandia",
      de: "Finnland",
      it: "Finlandia",
    },
    flag: "🇫🇮",
  },
  {
    cca2: "FJ",
    cca3: "FJI",
    translations: {
      en: "Fiji",
      fr: "Fidji",
      pt: "Fiji",
      es: "Fiyi",
      de: "Fidschi",
      it: "Figi",
    },
    flag: "🇫🇯",
  },
  {
    cca2: "FR",
    cca3: "FRA",
    translations: {
      en: "France",
      fr: "France",
      pt: "França",
      es: "Francia",
      de: "Frankreich",
      it: "Francia",
    },
    flag: "🇫🇷",
  },
  {
    cca2: "FM",
    cca3: "FSM",
    translations: {
      en: "Micronesia",
      fr: "Micronésie",
      pt: "Micronésia",
      es: "Micronesia",
      de: "Mikronesien",
      it: "Micronesia",
    },
    flag: "🇫🇲",
  },
  {
    cca2: "GA",
    cca3: "GAB",
    translations: {
      en: "Gabon",
      fr: "Gabon",
      pt: "Gabão",
      es: "Gabón",
      de: "Gabun",
      it: "Gabon",
    },
    flag: "🇬🇦",
  },
  {
    cca2: "GB",
    cca3: "GBR",
    translations: {
      en: "United Kingdom",
      fr: "Royaume-Uni",
      pt: "Reino Unido",
      es: "Reino Unido",
      de: "Vereinigtes Königreich",
      it: "Regno Unito",
    },
    flag: "🇬🇧",
  },
  {
    cca2: "GE",
    cca3: "GEO",
    translations: {
      en: "Georgia",
      fr: "Géorgie",
      pt: "Geórgia",
      es: "Georgia",
      de: "Georgien",
      it: "Georgia",
    },
    flag: "🇬🇪",
  },
  {
    cca2: "GH",
    cca3: "GHA",
    translations: {
      en: "Ghana",
      fr: "Ghana",
      pt: "Gana",
      es: "Ghana",
      de: "Ghana",
      it: "Ghana",
    },
    flag: "🇬🇭",
  },
  {
    cca2: "GN",
    cca3: "GIN",
    translations: {
      en: "Guinea",
      fr: "Guinée",
      pt: "Guiné",
      es: "Guinea",
      de: "Guinea",
      it: "Guinea",
    },
    flag: "🇬🇳",
  },
  {
    cca2: "GM",
    cca3: "GMB",
    translations: {
      en: "Gambia",
      fr: "Gambie",
      pt: "Gâmbia",
      es: "Gambia",
      de: "Gambia",
      it: "Gambia",
    },
    flag: "🇬🇲",
  },
  {
    cca2: "GQ",
    cca3: "GNQ",
    translations: {
      en: "Equatorial Guinea",
      fr: "Guinée équatoriale",
      pt: "Guiné Equatorial",
      es: "Guinea Ecuatorial",
      de: "Äquatorialguinea",
      it: "Guinea Equatoriale",
    },
    flag: "🇬🇶",
  },
  {
    cca2: "GR",
    cca3: "GRC",
    translations: {
      en: "Greece",
      fr: "Grèce",
      pt: "Grécia",
      es: "Grecia",
      de: "Griechenland",
      it: "Grecia",
    },
    flag: "🇬🇷",
  },
  {
    cca2: "GD",
    cca3: "GRD",
    translations: {
      en: "Grenada",
      fr: "Grenade",
      pt: "Granada",
      es: "Grenada",
      de: "Grenada",
      it: "Grenada",
    },
    flag: "🇬🇩",
  },
  {
    cca2: "GT",
    cca3: "GTM",
    translations: {
      en: "Guatemala",
      fr: "Guatemala",
      pt: "Guatemala",
      es: "Guatemala",
      de: "Guatemala",
      it: "Guatemala",
    },
    flag: "🇬🇹",
  },
  {
    cca2: "GY",
    cca3: "GUY",
    translations: {
      en: "Guyana",
      fr: "Guyana",
      pt: "Guiana",
      es: "Guyana",
      de: "Guyana",
      it: "Guyana",
    },
    flag: "🇬🇾",
  },
  {
    cca2: "HN",
    cca3: "HND",
    translations: {
      en: "Honduras",
      fr: "Honduras",
      pt: "Honduras",
      es: "Honduras",
      de: "Honduras",
      it: "Honduras",
    },
    flag: "🇭🇳",
  },
  {
    cca2: "HR",
    cca3: "HRV",
    translations: {
      en: "Croatia",
      fr: "Croatie",
      pt: "Croácia",
      es: "Croacia",
      de: "Kroatien",
      it: "Croazia",
    },
    flag: "🇭🇷",
  },
  {
    cca2: "HT",
    cca3: "HTI",
    translations: {
      en: "Haiti",
      fr: "Haïti",
      pt: "Haiti",
      es: "Haití",
      de: "Haiti",
      it: "Haiti",
    },
    flag: "🇭🇹",
  },
  {
    cca2: "HU",
    cca3: "HUN",
    translations: {
      en: "Hungary",
      fr: "Hongrie",
      pt: "Hungria",
      es: "Hungría",
      de: "Ungarn",
      it: "Ungheria",
    },
    flag: "🇭🇺",
  },
  {
    cca2: "ID",
    cca3: "IDN",
    translations: {
      en: "Indonesia",
      fr: "Indonésie",
      pt: "Indonésia",
      es: "Indonesia",
      de: "Indonesien",
      it: "Indonesia",
    },
    flag: "🇮🇩",
  },
  {
    cca2: "IN",
    cca3: "IND",
    translations: {
      en: "India",
      fr: "Inde",
      pt: "Índia",
      es: "India",
      de: "Indien",
      it: "India",
    },
    flag: "🇮🇳",
  },
  {
    cca2: "IE",
    cca3: "IRL",
    translations: {
      en: "Ireland",
      fr: "Irlande",
      pt: "Irlanda",
      es: "Irlanda",
      de: "Irland",
      it: "Irlanda",
    },
    flag: "🇮🇪",
  },
  {
    cca2: "IR",
    cca3: "IRN",
    translations: {
      en: "Iran",
      fr: "Iran",
      pt: "Irão",
      es: "Iran",
      de: "Iran",
      it: "Iran",
    },
    flag: "🇮🇷",
  },
  {
    cca2: "IQ",
    cca3: "IRQ",
    translations: {
      en: "Iraq",
      fr: "Irak",
      pt: "Iraque",
      es: "Irak",
      de: "Irak",
      it: "Iraq",
    },
    flag: "🇮🇶",
  },
  {
    cca2: "IS",
    cca3: "ISL",
    translations: {
      en: "Iceland",
      fr: "Islande",
      pt: "Islândia",
      es: "Islandia",
      de: "Island",
      it: "Islanda",
    },
    flag: "🇮🇸",
  },
  {
    cca2: "IL",
    cca3: "ISR",
    translations: {
      en: "Israel",
      fr: "Israël",
      pt: "Israel",
      es: "Israel",
      de: "Israel",
      it: "Israele",
    },
    flag: "🇮🇱",
  },
  {
    cca2: "IT",
    cca3: "ITA",
    translations: {
      en: "Italy",
      fr: "Italie",
      pt: "Itália",
      es: "Italia",
      de: "Italien",
      it: "Italia",
    },
    flag: "🇮🇹",
  },
  {
    cca2: "JM",
    cca3: "JAM",
    translations: {
      en: "Jamaica",
      fr: "Jamaïque",
      pt: "Jamaica",
      es: "Jamaica",
      de: "Jamaika",
      it: "Giamaica",
    },
    flag: "🇯🇲",
  },
  {
    cca2: "JO",
    cca3: "JOR",
    translations: {
      en: "Jordan",
      fr: "Jordanie",
      pt: "Jordânia",
      es: "Jordania",
      de: "Jordanien",
      it: "Giordania",
    },
    flag: "🇯🇴",
  },
  {
    cca2: "JP",
    cca3: "JPN",
    translations: {
      en: "Japan",
      fr: "Japon",
      pt: "Japão",
      es: "Japón",
      de: "Japan",
      it: "Giappone",
    },
    flag: "🇯🇵",
  },
  {
    cca2: "KZ",
    cca3: "KAZ",
    translations: {
      en: "Kazakhstan",
      fr: "Kazakhstan",
      pt: "Cazaquistão",
      es: "Kazajistán",
      de: "Kasachstan",
      it: "Kazakistan",
    },
    flag: "🇰🇿",
  },
  {
    cca2: "KE",
    cca3: "KEN",
    translations: {
      en: "Kenya",
      fr: "Kenya",
      pt: "Quénia",
      es: "Kenia",
      de: "Kenia",
      it: "Kenya",
    },
    flag: "🇰🇪",
  },
  {
    cca2: "KG",
    cca3: "KGZ",
    translations: {
      en: "Kyrgyzstan",
      fr: "Kirghizistan",
      pt: "Quirguistão",
      es: "Kirguizistán",
      de: "Kirgisistan",
      it: "Kirghizistan",
    },
    flag: "🇰🇬",
  },
  {
    cca2: "KH",
    cca3: "KHM",
    translations: {
      en: "Cambodia",
      fr: "Cambodge",
      pt: "Camboja",
      es: "Camboya",
      de: "Kambodscha",
      it: "Cambogia",
    },
    flag: "🇰🇭",
  },
  {
    cca2: "KI",
    cca3: "KIR",
    translations: {
      en: "Kiribati",
      fr: "Kiribati",
      pt: "Kiribati",
      es: "Kiribati",
      de: "Kiribati",
      it: "Kiribati",
    },
    flag: "🇰🇮",
  },
  {
    cca2: "KN",
    cca3: "KNA",
    translations: {
      en: "Saint Kitts and Nevis",
      fr: "Saint-Christophe-et-Niévès",
      pt: "São Cristóvão e Nevis",
      es: "San Cristóbal y Nieves",
      de: "St. Kitts und Nevis",
      it: "Saint Kitts e Nevis",
    },
    flag: "🇰🇳",
  },
  {
    cca2: "KR",
    cca3: "KOR",
    translations: {
      en: "South Korea",
      fr: "Corée du Sud",
      pt: "Coreia do Sul",
      es: "Corea del Sur",
      de: "Südkorea",
      it: "Corea del Sud",
    },
    flag: "🇰🇷",
  },
  {
    cca2: "KW",
    cca3: "KWT",
    translations: {
      en: "Kuwait",
      fr: "Koweït",
      pt: "Kuwait",
      es: "Kuwait",
      de: "Kuwait",
      it: "Kuwait",
    },
    flag: "🇰🇼",
  },
  {
    cca2: "LA",
    cca3: "LAO",
    translations: {
      en: "Laos",
      fr: "Laos",
      pt: "Laos",
      es: "Laos",
      de: "Laos",
      it: "Laos",
    },
    flag: "🇱🇦",
  },
  {
    cca2: "LB",
    cca3: "LBN",
    translations: {
      en: "Lebanon",
      fr: "Liban",
      pt: "Líbano",
      es: "Líbano",
      de: "Libanon",
      it: "Libano",
    },
    flag: "🇱🇧",
  },
  {
    cca2: "LR",
    cca3: "LBR",
    translations: {
      en: "Liberia",
      fr: "Liberia",
      pt: "Libéria",
      es: "Liberia",
      de: "Liberia",
      it: "Liberia",
    },
    flag: "🇱🇷",
  },
  {
    cca2: "LY",
    cca3: "LBY",
    translations: {
      en: "Libya",
      fr: "Libye",
      pt: "Líbia",
      es: "Libia",
      de: "Libyen",
      it: "Libia",
    },
    flag: "🇱🇾",
  },
  {
    cca2: "LC",
    cca3: "LCA",
    translations: {
      en: "Saint Lucia",
      fr: "Sainte-Lucie",
      pt: "Santa Lúcia",
      es: "Santa Lucía",
      de: "St. Lucia",
      it: "Santa Lucia",
    },
    flag: "🇱🇨",
  },
  {
    cca2: "LI",
    cca3: "LIE",
    translations: {
      en: "Liechtenstein",
      fr: "Liechtenstein",
      pt: "Liechtenstein",
      es: "Liechtenstein",
      de: "Liechtenstein",
      it: "Liechtenstein",
    },
    flag: "🇱🇮",
  },
  {
    cca2: "LK",
    cca3: "LKA",
    translations: {
      en: "Sri Lanka",
      fr: "Sri Lanka",
      pt: "Sri Lanka",
      es: "Sri Lanka",
      de: "Sri Lanka",
      it: "Sri Lanka",
    },
    flag: "🇱🇰",
  },
  {
    cca2: "LS",
    cca3: "LSO",
    translations: {
      en: "Lesotho",
      fr: "Lesotho",
      pt: "Lesoto",
      es: "Lesotho",
      de: "Lesotho",
      it: "Lesotho",
    },
    flag: "🇱🇸",
  },
  {
    cca2: "LT",
    cca3: "LTU",
    translations: {
      en: "Lithuania",
      fr: "Lituanie",
      pt: "Lituânia",
      es: "Lituania",
      de: "Litauen",
      it: "Lituania",
    },
    flag: "🇱🇹",
  },
  {
    cca2: "LU",
    cca3: "LUX",
    translations: {
      en: "Luxembourg",
      fr: "Luxembourg",
      pt: "Luxemburgo",
      es: "Luxemburgo",
      de: "Luxemburg",
      it: "Lussemburgo",
    },
    flag: "🇱🇺",
  },
  {
    cca2: "LV",
    cca3: "LVA",
    translations: {
      en: "Latvia",
      fr: "Lettonie",
      pt: "Letónia",
      es: "Letonia",
      de: "Lettland",
      it: "Lettonia",
    },
    flag: "🇱🇻",
  },
  {
    cca2: "MA",
    cca3: "MAR",
    translations: {
      en: "Morocco",
      fr: "Maroc",
      pt: "Marrocos",
      es: "Marruecos",
      de: "Marokko",
      it: "Marocco",
    },
    flag: "🇲🇦",
  },
  {
    cca2: "MC",
    cca3: "MCO",
    translations: {
      en: "Monaco",
      fr: "Monaco",
      pt: "Mónaco",
      es: "Mónaco",
      de: "Monaco",
      it: "Principato di Monaco",
    },
    flag: "🇲🇨",
  },
  {
    cca2: "MD",
    cca3: "MDA",
    translations: {
      en: "Moldova",
      fr: "Moldavie",
      pt: "Moldávia",
      es: "Moldavia",
      de: "Moldawien",
      it: "Moldavia",
    },
    flag: "🇲🇩",
  },
  {
    cca2: "MG",
    cca3: "MDG",
    translations: {
      en: "Madagascar",
      fr: "Madagascar",
      pt: "Madagáscar",
      es: "Madagascar",
      de: "Madagaskar",
      it: "Madagascar",
    },
    flag: "🇲🇬",
  },
  {
    cca2: "MV",
    cca3: "MDV",
    translations: {
      en: "Maldives",
      fr: "Maldives",
      pt: "Maldivas",
      es: "Maldivas",
      de: "Malediven",
      it: "Maldive",
    },
    flag: "🇲🇻",
  },
  {
    cca2: "MX",
    cca3: "MEX",
    translations: {
      en: "Mexico",
      fr: "Mexique",
      pt: "México",
      es: "México",
      de: "Mexiko",
      it: "Messico",
    },
    flag: "🇲🇽",
  },
  {
    cca2: "MH",
    cca3: "MHL",
    translations: {
      en: "Marshall Islands",
      fr: "Îles Marshall",
      pt: "Ilhas Marshall",
      es: "Islas Marshall",
      de: "Marshallinseln",
      it: "Isole Marshall",
    },
    flag: "🇲🇭",
  },
  {
    cca2: "MK",
    cca3: "MKD",
    translations: {
      en: "North Macedonia",
      fr: "Macédoine du Nord",
      pt: "Macedónia do Norte",
      es: "Macedonia del Norte",
      de: "Nordmazedonien",
      it: "Macedonia del Nord",
    },
    flag: "🇲🇰",
  },
  {
    cca2: "ML",
    cca3: "MLI",
    translations: {
      en: "Mali",
      fr: "Mali",
      pt: "Mali",
      es: "Mali",
      de: "Mali",
      it: "Mali",
    },
    flag: "🇲🇱",
  },
  {
    cca2: "MT",
    cca3: "MLT",
    translations: {
      en: "Malta",
      fr: "Malte",
      pt: "Malta",
      es: "Malta",
      de: "Malta",
      it: "Malta",
    },
    flag: "🇲🇹",
  },
  {
    cca2: "MM",
    cca3: "MMR",
    translations: {
      en: "Myanmar",
      fr: "Birmanie",
      pt: "Myanmar",
      es: "Myanmar",
      de: "Myanmar",
      it: "Birmania",
    },
    flag: "🇲🇲",
  },
  {
    cca2: "ME",
    cca3: "MNE",
    translations: {
      en: "Montenegro",
      fr: "Monténégro",
      pt: "Montenegro",
      es: "Montenegro",
      de: "Montenegro",
      it: "Montenegro",
    },
    flag: "🇲🇪",
  },
  {
    cca2: "MN",
    cca3: "MNG",
    translations: {
      en: "Mongolia",
      fr: "Mongolie",
      pt: "Mongólia",
      es: "Mongolia",
      de: "Mongolei",
      it: "Mongolia",
    },
    flag: "🇲🇳",
  },
  {
    cca2: "MZ",
    cca3: "MOZ",
    translations: {
      en: "Mozambique",
      fr: "Mozambique",
      pt: "Moçambique",
      es: "Mozambique",
      de: "Mosambik",
      it: "Mozambico",
    },
    flag: "🇲🇿",
  },
  {
    cca2: "MR",
    cca3: "MRT",
    translations: {
      en: "Mauritania",
      fr: "Mauritanie",
      pt: "Mauritânia",
      es: "Mauritania",
      de: "Mauretanien",
      it: "Mauritania",
    },
    flag: "🇲🇷",
  },
  {
    cca2: "MU",
    cca3: "MUS",
    translations: {
      en: "Mauritius",
      fr: "Île Maurice",
      pt: "Maurício",
      es: "Mauricio",
      de: "Mauritius",
      it: "Mauritius",
    },
    flag: "🇲🇺",
  },
  {
    cca2: "MW",
    cca3: "MWI",
    translations: {
      en: "Malawi",
      fr: "Malawi",
      pt: "Malawi",
      es: "Malawi",
      de: "Malawi",
      it: "Malawi",
    },
    flag: "🇲🇼",
  },
  {
    cca2: "MY",
    cca3: "MYS",
    translations: {
      en: "Malaysia",
      fr: "Malaisie",
      pt: "Malásia",
      es: "Malasia",
      de: "Malaysia",
      it: "Malesia",
    },
    flag: "🇲🇾",
  },
  {
    cca2: "NA",
    cca3: "NAM",
    translations: {
      en: "Namibia",
      fr: "Namibie",
      pt: "Namíbia",
      es: "Namibia",
      de: "Namibia",
      it: "Namibia",
    },
    flag: "🇳🇦",
  },
  {
    cca2: "NE",
    cca3: "NER",
    translations: {
      en: "Niger",
      fr: "Niger",
      pt: "Níger",
      es: "Níger",
      de: "Niger",
      it: "Niger",
    },
    flag: "🇳🇪",
  },
  {
    cca2: "NG",
    cca3: "NGA",
    translations: {
      en: "Nigeria",
      fr: "Nigéria",
      pt: "Nigéria",
      es: "Nigeria",
      de: "Nigeria",
      it: "Nigeria",
    },
    flag: "🇳🇬",
  },
  {
    cca2: "NI",
    cca3: "NIC",
    translations: {
      en: "Nicaragua",
      fr: "Nicaragua",
      pt: "Nicarágua",
      es: "Nicaragua",
      de: "Nicaragua",
      it: "Nicaragua",
    },
    flag: "🇳🇮",
  },
  {
    cca2: "NL",
    cca3: "NLD",
    translations: {
      en: "Netherlands",
      fr: "Pays-Bas",
      pt: "Holanda",
      es: "Países Bajos",
      de: "Niederlande",
      it: "Paesi Bassi",
    },
    flag: "🇳🇱",
  },
  {
    cca2: "NO",
    cca3: "NOR",
    translations: {
      en: "Norway",
      fr: "Norvège",
      pt: "Noruega",
      es: "Noruega",
      de: "Norwegen",
      it: "Norvegia",
    },
    flag: "🇳🇴",
  },
  {
    cca2: "NP",
    cca3: "NPL",
    translations: {
      en: "Nepal",
      fr: "Népal",
      pt: "Nepal",
      es: "Nepal",
      de: "Nepal",
      it: "Nepal",
    },
    flag: "🇳🇵",
  },
  {
    cca2: "NR",
    cca3: "NRU",
    translations: {
      en: "Nauru",
      fr: "Nauru",
      pt: "Nauru",
      es: "Nauru",
      de: "Nauru",
      it: "Nauru",
    },
    flag: "🇳🇷",
  },
  {
    cca2: "NZ",
    cca3: "NZL",
    translations: {
      en: "New Zealand",
      fr: "Nouvelle-Zélande",
      pt: "Nova Zelândia",
      es: "Nueva Zelanda",
      de: "Neuseeland",
      it: "Nuova Zelanda",
    },
    flag: "🇳🇿",
  },
  {
    cca2: "OM",
    cca3: "OMN",
    translations: {
      en: "Oman",
      fr: "Oman",
      pt: "Omã",
      es: "Omán",
      de: "Oman",
      it: "oman",
    },
    flag: "🇴🇲",
  },
  {
    cca2: "PK",
    cca3: "PAK",
    translations: {
      en: "Pakistan",
      fr: "Pakistan",
      pt: "Paquistão",
      es: "Pakistán",
      de: "Pakistan",
      it: "Pakistan",
    },
    flag: "🇵🇰",
  },
  {
    cca2: "PA",
    cca3: "PAN",
    translations: {
      en: "Panama",
      fr: "Panama",
      pt: "Panamá",
      es: "Panamá",
      de: "Panama",
      it: "Panama",
    },
    flag: "🇵🇦",
  },
  {
    cca2: "PE",
    cca3: "PER",
    translations: {
      en: "Peru",
      fr: "Pérou",
      pt: "Perú",
      es: "Perú",
      de: "Peru",
      it: "Perù",
    },
    flag: "🇵🇪",
  },
  {
    cca2: "PH",
    cca3: "PHL",
    translations: {
      en: "Philippines",
      fr: "Philippines",
      pt: "Filipinas",
      es: "Filipinas",
      de: "Philippinen",
      it: "Filippine",
    },
    flag: "🇵🇭",
  },
  {
    cca2: "PW",
    cca3: "PLW",
    translations: {
      en: "Palau",
      fr: "Palaos (Palau)",
      pt: "Palau",
      es: "Palau",
      de: "Palau",
      it: "Palau",
    },
    flag: "🇵🇼",
  },
  {
    cca2: "PG",
    cca3: "PNG",
    translations: {
      en: "Papua New Guinea",
      fr: "Papouasie-Nouvelle-Guinée",
      pt: "Papua Nova Guiné",
      es: "Papúa Nueva Guinea",
      de: "Papua-Neuguinea",
      it: "Papua Nuova Guinea",
    },
    flag: "🇵🇬",
  },
  {
    cca2: "PL",
    cca3: "POL",
    translations: {
      en: "Poland",
      fr: "Pologne",
      pt: "Polónia",
      es: "Polonia",
      de: "Polen",
      it: "Polonia",
    },
    flag: "🇵🇱",
  },
  {
    cca2: "KP",
    cca3: "PRK",
    translations: {
      en: "North Korea",
      fr: "Corée du Nord",
      pt: "Coreia do Norte",
      es: "Corea del Norte",
      de: "Nordkorea",
      it: "Corea del Nord",
    },
    flag: "🇰🇵",
  },
  {
    cca2: "PT",
    cca3: "PRT",
    translations: {
      en: "Portugal",
      fr: "Portugal",
      pt: "Portugal",
      es: "Portugal",
      de: "Portugal",
      it: "Portogallo",
    },
    flag: "🇵🇹",
  },
  {
    cca2: "PY",
    cca3: "PRY",
    translations: {
      en: "Paraguay",
      fr: "Paraguay",
      pt: "Paraguai",
      es: "Paraguay",
      de: "Paraguay",
      it: "Paraguay",
    },
    flag: "🇵🇾",
  },
  {
    cca2: "QA",
    cca3: "QAT",
    translations: {
      en: "Qatar",
      fr: "Qatar",
      pt: "Catar",
      es: "Catar",
      de: "Katar",
      it: "Qatar",
    },
    flag: "🇶🇦",
  },
  {
    cca2: "RO",
    cca3: "ROU",
    translations: {
      en: "Romania",
      fr: "Roumanie",
      pt: "Roménia",
      es: "Rumania",
      de: "Rumänien",
      it: "Romania",
    },
    flag: "🇷🇴",
  },
  {
    cca2: "RU",
    cca3: "RUS",
    translations: {
      en: "Russia",
      fr: "Russie",
      pt: "Rússia",
      es: "Rusia",
      de: "Russland",
      it: "Russia",
    },
    flag: "🇷🇺",
  },
  {
    cca2: "RW",
    cca3: "RWA",
    translations: {
      en: "Rwanda",
      fr: "Rwanda",
      pt: "Ruanda",
      es: "Ruanda",
      de: "Ruanda",
      it: "Ruanda",
    },
    flag: "🇷🇼",
  },
  {
    cca2: "SA",
    cca3: "SAU",
    translations: {
      en: "Saudi Arabia",
      fr: "Arabie Saoudite",
      pt: "Arábia Saudita",
      es: "Arabia Saudí",
      de: "Saudi-Arabien",
      it: "Arabia Saudita",
    },
    flag: "🇸🇦",
  },
  {
    cca2: "SD",
    cca3: "SDN",
    translations: {
      en: "Sudan",
      fr: "Soudan",
      pt: "Sudão",
      es: "Sudán",
      de: "Sudan",
      it: "Sudan",
    },
    flag: "🇸🇩",
  },
  {
    cca2: "SN",
    cca3: "SEN",
    translations: {
      en: "Senegal",
      fr: "Sénégal",
      pt: "Senegal",
      es: "Senegal",
      de: "Senegal",
      it: "Senegal",
    },
    flag: "🇸🇳",
  },
  {
    cca2: "SG",
    cca3: "SGP",
    translations: {
      en: "Singapore",
      fr: "Singapour",
      pt: "Singapura",
      es: "Singapur",
      de: "Singapur",
      it: "Singapore",
    },
    flag: "🇸🇬",
  },
  {
    cca2: "SB",
    cca3: "SLB",
    translations: {
      en: "Solomon Islands",
      fr: "Îles Salomon",
      pt: "Ilhas Salomão",
      es: "Islas Salomón",
      de: "Salomonen",
      it: "Isole Salomone",
    },
    flag: "🇸🇧",
  },
  {
    cca2: "SL",
    cca3: "SLE",
    translations: {
      en: "Sierra Leone",
      fr: "Sierra Leone",
      pt: "Serra Leoa",
      es: "Sierra Leone",
      de: "Sierra Leone",
      it: "Sierra Leone",
    },
    flag: "🇸🇱",
  },
  {
    cca2: "SV",
    cca3: "SLV",
    translations: {
      en: "El Salvador",
      fr: "Salvador",
      pt: "El Salvador",
      es: "El Salvador",
      de: "El Salvador",
      it: "El Salvador",
    },
    flag: "🇸🇻",
  },
  {
    cca2: "SM",
    cca3: "SMR",
    translations: {
      en: "San Marino",
      fr: "Saint-Marin",
      pt: "San Marino",
      es: "San Marino",
      de: "San Marino",
      it: "San Marino",
    },
    flag: "🇸🇲",
  },
  {
    cca2: "SO",
    cca3: "SOM",
    translations: {
      en: "Somalia",
      fr: "Somalie",
      pt: "Somália",
      es: "Somalia",
      de: "Somalia",
      it: "Somalia",
    },
    flag: "🇸🇴",
  },
  {
    cca2: "RS",
    cca3: "SRB",
    translations: {
      en: "Serbia",
      fr: "Serbie",
      pt: "Sérvia",
      es: "Serbia",
      de: "Serbien",
      it: "Serbia",
    },
    flag: "🇷🇸",
  },
  {
    cca2: "SS",
    cca3: "SSD",
    translations: {
      en: "South Sudan",
      fr: "Soudan du Sud",
      pt: "Sudão do Sul",
      es: "Sudán del Sur",
      de: "Südsudan",
      it: "Sudan del sud",
    },
    flag: "🇸🇸",
  },
  {
    cca2: "ST",
    cca3: "STP",
    translations: {
      en: "São Tomé and Príncipe",
      fr: "São Tomé et Príncipe",
      pt: "São Tomé e Príncipe",
      es: "Santo Tomé y Príncipe",
      de: "São Tomé und Príncipe",
      it: "São Tomé e Príncipe",
    },
    flag: "🇸🇹",
  },
  {
    cca2: "SR",
    cca3: "SUR",
    translations: {
      en: "Suriname",
      fr: "Surinam",
      pt: "Suriname",
      es: "Surinam",
      de: "Suriname",
      it: "Suriname",
    },
    flag: "🇸🇷",
  },
  {
    cca2: "SK",
    cca3: "SVK",
    translations: {
      en: "Slovakia",
      fr: "Slovaquie",
      pt: "Eslováquia",
      es: "República Eslovaca",
      de: "Slowakei",
      it: "Slovacchia",
    },
    flag: "🇸🇰",
  },
  {
    cca2: "SI",
    cca3: "SVN",
    translations: {
      en: "Slovenia",
      fr: "Slovénie",
      pt: "Eslovénia",
      es: "Eslovenia",
      de: "Slowenien",
      it: "Slovenia",
    },
    flag: "🇸🇮",
  },
  {
    cca2: "SE",
    cca3: "SWE",
    translations: {
      en: "Sweden",
      fr: "Suède",
      pt: "Suécia",
      es: "Suecia",
      de: "Schweden",
      it: "Svezia",
    },
    flag: "🇸🇪",
  },
  {
    cca2: "SZ",
    cca3: "SWZ",
    translations: {
      en: "Eswatini",
      fr: "Swaziland",
      pt: "Suazilândia",
      es: "Suazilandia",
      de: "Swasiland",
      it: "Swaziland",
    },
    flag: "🇸🇿",
  },
  {
    cca2: "SC",
    cca3: "SYC",
    translations: {
      en: "Seychelles",
      fr: "Seychelles",
      pt: "Seicheles",
      es: "Seychelles",
      de: "Seychellen",
      it: "Seychelles",
    },
    flag: "🇸🇨",
  },
  {
    cca2: "SY",
    cca3: "SYR",
    translations: {
      en: "Syria",
      fr: "Syrie",
      pt: "Síria",
      es: "Siria",
      de: "Syrien",
      it: "Siria",
    },
    flag: "🇸🇾",
  },
  {
    cca2: "TD",
    cca3: "TCD",
    translations: {
      en: "Chad",
      fr: "Tchad",
      pt: "Chade",
      es: "Chad",
      de: "Tschad",
      it: "Ciad",
    },
    flag: "🇹🇩",
  },
  {
    cca2: "TG",
    cca3: "TGO",
    translations: {
      en: "Togo",
      fr: "Togo",
      pt: "Togo",
      es: "Togo",
      de: "Togo",
      it: "Togo",
    },
    flag: "🇹🇬",
  },
  {
    cca2: "TH",
    cca3: "THA",
    translations: {
      en: "Thailand",
      fr: "Thaïlande",
      pt: "Tailândia",
      es: "Tailandia",
      de: "Thailand",
      it: "Tailandia",
    },
    flag: "🇹🇭",
  },
  {
    cca2: "TJ",
    cca3: "TJK",
    translations: {
      en: "Tajikistan",
      fr: "Tadjikistan",
      pt: "Tajiquistão",
      es: "Tayikistán",
      de: "Tadschikistan",
      it: "Tagikistan",
    },
    flag: "🇹🇯",
  },
  {
    cca2: "TM",
    cca3: "TKM",
    translations: {
      en: "Turkmenistan",
      fr: "Turkménistan",
      pt: "Turquemenistão",
      es: "Turkmenistán",
      de: "Turkmenistan",
      it: "Turkmenistan",
    },
    flag: "🇹🇲",
  },
  {
    cca2: "TL",
    cca3: "TLS",
    translations: {
      en: "Timor-Leste",
      fr: "Timor oriental",
      pt: "Timor-Leste",
      es: "Timor Oriental",
      de: "Osttimor",
      it: "Timor Est",
    },
    flag: "🇹🇱",
  },
  {
    cca2: "TO",
    cca3: "TON",
    translations: {
      en: "Tonga",
      fr: "Tonga",
      pt: "Tonga",
      es: "Tonga",
      de: "Tonga",
      it: "Tonga",
    },
    flag: "🇹🇴",
  },
  {
    cca2: "TT",
    cca3: "TTO",
    translations: {
      en: "Trinidad and Tobago",
      fr: "Trinité-et-Tobago",
      pt: "Trinidade e Tobago",
      es: "Trinidad y Tobago",
      de: "Trinidad und Tobago",
      it: "Trinidad e Tobago",
    },
    flag: "🇹🇹",
  },
  {
    cca2: "TN",
    cca3: "TUN",
    translations: {
      en: "Tunisia",
      fr: "Tunisie",
      pt: "Tunísia",
      es: "Túnez",
      de: "Tunesien",
      it: "Tunisia",
    },
    flag: "🇹🇳",
  },
  {
    cca2: "TR",
    cca3: "TUR",
    translations: {
      en: "Turkey",
      fr: "Turquie",
      pt: "Turquia",
      es: "Turquía",
      de: "Türkei",
      it: "Turchia",
    },
    flag: "🇹🇷",
  },
  {
    cca2: "TV",
    cca3: "TUV",
    translations: {
      en: "Tuvalu",
      fr: "Tuvalu",
      pt: "Tuvalu",
      es: "Tuvalu",
      de: "Tuvalu",
      it: "Tuvalu",
    },
    flag: "🇹🇻",
  },
  {
    cca2: "TZ",
    cca3: "TZA",
    translations: {
      en: "Tanzania",
      fr: "Tanzanie",
      pt: "Tanzânia",
      es: "Tanzania",
      de: "Tansania",
      it: "Tanzania",
    },
    flag: "🇹🇿",
  },
  {
    cca2: "UG",
    cca3: "UGA",
    translations: {
      en: "Uganda",
      fr: "Ouganda",
      pt: "Uganda",
      es: "Uganda",
      de: "Uganda",
      it: "Uganda",
    },
    flag: "🇺🇬",
  },
  {
    cca2: "UA",
    cca3: "UKR",
    translations: {
      en: "Ukraine",
      fr: "Ukraine",
      pt: "Ucrânia",
      es: "Ucrania",
      de: "Ukraine",
      it: "Ucraina",
    },
    flag: "🇺🇦",
  },
  {
    cca2: "UY",
    cca3: "URY",
    translations: {
      en: "Uruguay",
      fr: "Uruguay",
      pt: "Uruguai",
      es: "Uruguay",
      de: "Uruguay",
      it: "Uruguay",
    },
    flag: "🇺🇾",
  },
  {
    cca2: "US",
    cca3: "USA",
    translations: {
      en: "United States",
      fr: "États-Unis",
      pt: "Estados Unidos",
      es: "Estados Unidos",
      de: "Vereinigte Staaten",
      it: "Stati Uniti d'America",
    },
    flag: "🇺🇸",
  },
  {
    cca2: "UZ",
    cca3: "UZB",
    translations: {
      en: "Uzbekistan",
      fr: "Ouzbékistan",
      pt: "Uzbequistão",
      es: "Uzbekistán",
      de: "Usbekistan",
      it: "Uzbekistan",
    },
    flag: "🇺🇿",
  },
  {
    cca2: "VA",
    cca3: "VAT",
    translations: {
      en: "Vatican City",
      fr: "Cité du Vatican",
      pt: "Cidade do Vaticano",
      es: "Ciudad del Vaticano",
      de: "Vatikanstadt",
      it: "Città del Vaticano",
    },
    flag: "🇻🇦",
  },
  {
    cca2: "VC",
    cca3: "VCT",
    translations: {
      en: "Saint Vincent and the Grenadines",
      fr: "Saint-Vincent-et-les-Grenadines",
      pt: "São Vincente e Granadinas",
      es: "San Vicente y Granadinas",
      de: "St. Vincent und die Grenadinen",
      it: "Saint Vincent e Grenadine",
    },
    flag: "🇻🇨",
  },
  {
    cca2: "VE",
    cca3: "VEN",
    translations: {
      en: "Venezuela",
      fr: "Venezuela",
      pt: "Venezuela",
      es: "Venezuela",
      de: "Venezuela",
      it: "Venezuela",
    },
    flag: "🇻🇪",
  },
  {
    cca2: "VN",
    cca3: "VNM",
    translations: {
      en: "Vietnam",
      fr: "Viêt Nam",
      pt: "Vietname",
      es: "Vietnam",
      de: "Vietnam",
      it: "Vietnam",
    },
    flag: "🇻🇳",
  },
  {
    cca2: "VU",
    cca3: "VUT",
    translations: {
      en: "Vanuatu",
      fr: "Vanuatu",
      pt: "Vanuatu",
      es: "Vanuatu",
      de: "Vanuatu",
      it: "Vanuatu",
    },
    flag: "🇻🇺",
  },
  {
    cca2: "WS",
    cca3: "WSM",
    translations: {
      en: "Samoa",
      fr: "Samoa",
      pt: "Samoa",
      es: "Samoa",
      de: "Samoa",
      it: "Samoa",
    },
    flag: "🇼🇸",
  },
  {
    cca2: "YE",
    cca3: "YEM",
    translations: {
      en: "Yemen",
      fr: "Yémen",
      pt: "Iémen",
      es: "Yemen",
      de: "Jemen",
      it: "Yemen",
    },
    flag: "🇾🇪",
  },
  {
    cca2: "ZA",
    cca3: "ZAF",
    translations: {
      en: "South Africa",
      fr: "Afrique du Sud",
      pt: "África do Sul",
      es: "República de Sudáfrica",
      de: "Südafrika",
      it: "Sud Africa",
    },
    flag: "🇿🇦",
  },
  {
    cca2: "ZM",
    cca3: "ZMB",
    translations: {
      en: "Zambia",
      fr: "Zambie",
      pt: "Zâmbia",
      es: "Zambia",
      de: "Sambia",
      it: "Zambia",
    },
    flag: "🇿🇲",
  },
  {
    cca2: "ZW",
    cca3: "ZWE",
    translations: {
      en: "Zimbabwe",
      fr: "Zimbabwe",
      pt: "Zimbabwe",
      es: "Zimbabue",
      de: "Simbabwe",
      it: "Zimbabwe",
    },
    flag: "🇿🇼",
  },
]
