import React from "react"

import Separator from "~/components/Separator/Separator"
import { Body } from "~/components/ui"

import css from "./EditableField.module.scss"

type EditableFieldProps = {
  title?: string
  actionText: string
  actionCallback?: () => void
  input?: React.ReactNode
  children: React.ReactNode
}

export function EditableField({
  title,
  actionText,
  actionCallback,
  input,
  children,
  ...props
}: EditableFieldProps) {
  return (
    <div className={css.root} {...props}>
      <div className={css.header} {...props}>
        <Body className={css.title} bold variant="body1">
          {title ? title : ""}
        </Body>
        {input ?? (
          <Body
            className={css.actionText}
            color="original"
            semiBold
            onClick={actionCallback}
            variant="body2"
          >
            {actionText}
          </Body>
        )}
      </div>
      <div className={css.childrenContainer}>{children}</div>
      <Separator />
    </div>
  )
}
