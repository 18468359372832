import React from "react"

import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

import { UserUpdateSelectField } from "~/components/Forms/AccountSettingsForm/Fields/EditableFields/UserUpdateSelectField/UserUpdateSelectField"
import { SettingsField } from "~/components/Forms/AccountSettingsForm/Fields/SettingsFields/SettingsField"
import { Body, Heading } from "~/components/ui"
import { navigate } from "~/gatsby/navigate"
import { logout } from "~/state/modules/auth"
import { userSelector } from "~/state/modules/userInfo"
import { ALL_COUNTRIES } from "~/utils/countries-filtered"
import { allLocales, ChefclubLocale } from "~/utils/locales"

import css from "./LanguageSettingsForm.module.scss"

type ChefclubLocaleBases = "fr" | "de" | "it" | "en" | "pt"

export function LanguageSettingsForm() {
  const intl = useIntl()
  const user = useSelector(userSelector)

  const allCountriesMap = new Map()
  for (const country of ALL_COUNTRIES) {
    allCountriesMap.set(
      country.cca2,
      country.translations[intl.locale.split("-")[0] as ChefclubLocaleBases]
    )
  }

  const allCountriesSortedMap = new Map(
    [...allCountriesMap.entries()].sort((a, b) => {
      const aNormalize = a[1].normalize("NFD").toLowerCase()
      const bNormalize = b[1].normalize("NFD").toLowerCase()
      if (aNormalize < bNormalize) {
        return -1
      }
      if (aNormalize > bNormalize) {
        return 1
      }
      return 0
    })
  )

  const dispatch = useDispatch()

  const handleLogout = async () => {
    dispatch(logout())
    await navigate(`/${intl.locale}`)
  }

  return (
    <div className={css.root}>
      <Heading variant="medium">
        {intl.formatMessage({
          id: "form/text:title-my-settings",
        })}
      </Heading>

      <UserUpdateSelectField
        title={intl.formatMessage({
          id: "form/text:label-language",
        })}
        initialValue={{
          chosen_lang: user?.chosen_lang ?? "",
        }}
        valueToShow={
          user?.chosen_lang
            ? allLocales.get(user?.chosen_lang as ChefclubLocale)
            : ""
        }
        keyToChange="chosen_lang"
        apiKey="chosen_lang"
        possibleValues={allLocales}
      />

      <UserUpdateSelectField
        title={intl.formatMessage({
          id: "form/text:label-country",
        })}
        initialValue={{
          chosen_country: user?.chosen_country ?? "",
        }}
        valueToShow={
          user?.chosen_country
            ? allCountriesSortedMap.get(user?.chosen_country)
            : ""
        }
        keyToChange="chosen_country"
        apiKey="chosen_country"
        possibleValues={allCountriesSortedMap}
      />

      <SettingsField>
        <Body
          color="gray2"
          semiBold
          onClick={handleLogout}
          variant="body2"
          className={css.actionText}
        >
          <FormattedMessage id="header/text:logout" />
        </Body>
      </SettingsField>
    </div>
  )
}
