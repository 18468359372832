import React from "react"

import { useIntl } from "react-intl"
import { useSelector } from "react-redux"

import Breadcrumb from "~/components/Breadcrumb/Breadcrumb"
import { LoginForm } from "~/components/Forms"
import { AccountSettingsForm } from "~/components/Forms/AccountSettingsForm/AccountSettingsForm"
import { Container } from "~/components/ui"
import UserFormTemplate from "~/components/UserFormTemplate/UserFormTemplate"
import useIsClient from "~/hooks/useIsClient"
import { authSelector } from "~/state/modules/auth"
import withTemplate from "~/templates/withTemplate"

import css from "./Settings.module.scss"

const AccountSettings = () => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "profile/text:my-profile" })
  const { isLoggedIn } = useSelector(authSelector)
  const isClient = useIsClient()

  const welcomeMessage = [
    intl.formatMessage({ id: "profile/text:you-are-back" }),
    intl.formatMessage({ id: "profile/text:connect" }),
  ].join(" ")

  return (
    <div className={css.root}>
      {isLoggedIn ? (
        <>
          <Breadcrumb pageTitle={pageTitle} template={"Profile"} />
          <Container>
            <AccountSettingsForm />
          </Container>
        </>
      ) : isClient ? (
        // If user is logged out
        <UserFormTemplate
          title={welcomeMessage}
          Form={
            <LoginForm
              onLogIn={() => {
                null // To prevent default useAutoRedirect to profile page after login
              }}
            />
          }
        />
      ) : null}
    </div>
  )
}

export default withTemplate(AccountSettings)
